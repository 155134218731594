<template>
<b-overlay
  :show = "this.loading"
  spinner-variant="primary"
>
<div id="shippingInfoDiv">
    <div>      
      <b-card :title="$t('shippingTerms')" >
        <validation-provider 
          #default="{ errors }"
          name="Shipping Terms" 
          rules="required"
        >
        <b-form-input disabled label="Shipping terms" v-model="shippingTerms" class="sm:w-1/4" name="item-shipping-terms" /> 
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      </b-card>    
    </div>


  <div >   
    <b-card :title="$t('shippingLocations')">
      <app-collapse>
        <app-collapse-item :title="$t('includeSpecificStates')">
          <div>
            <v-select
             disable-lookup
              v-model="shippingStates"
              dir= "ltr" 
              multiple
              label="title"
              :options="allStatesNames"
            />
          </div>
        </app-collapse-item>

        <app-collapse-item :title="$t('excludeSpecificPostalCodes')">

        <b-row>  
          <div> 
              <b-button
                @click="selectAll"
                type="submit"
                variant="primary"
                class="mr-1"
                >
                  Select All 
                </b-button>
            </div>

            <div> 
              <b-button
                @click="clearAll"
                type="submit"
                variant="primary"
                class="mr-1"
                >
                  Clear All 
                </b-button>
            </div>
        </b-row>
            
          <div class="m-2"> 
            <vue-good-table
              :key = 'keykey'
              ref = 'excludedCodes'
              :columns="columns"
              :rows="allPostalCodes"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm }"
              :select-options="{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: $t('Εξαιρέσεις Τ.Κ.'),
                clearSelectionText: '',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :sort-options="{
                enabled: true,
                initialSortBy: {field: 'postal_code', type: 'asc'}
              }"
              :pagination-options="{
                enabled: true,
                perPage:perPage
              }"
            >
            

              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">         
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div> 

          
        </app-collapse-item>  
      </app-collapse>
    </b-card>
  </div> 

  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      @click="submitForm"
      variant="primary"
      class="mr-1"           
      >
      {{ $t('submit') }}
    </b-button>
  </div>
</div>

</b-overlay>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormText, VBModal, BOverlay, BCardText,  
  BTable, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import moduleUser from '@/store/user/moduleUser.js'
import moduleShipping from '@/store/shipping/moduleShipping.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import { required } from '@validations'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BPagination,
    BDropdown, 
    BDropdownItem,
    BButton,
    BCard,
    BTable,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent,
    BFormText,
    VBModal,
    vSelect,
    BCardText,
    vSelect,
    VueGoodTable,
    moduleShipping,
    moduleUser,
    required
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      keykey: 0,
      columns: [
        {
          label: 'Postal Code',
          field: 'postal_code',
        },
        {
          label: 'Postal Area',
          field: 'postal_area',
        },
        {
          label: 'State',
          field: 'state',
        },
      ],
      filter: null,
      perPage: 10,
      currentPage: 1,
      totalRows : 0,
      searchTerm : '',
      user: null,
      shippingTerms: null,
      includeSpecificStates: false,
      excludeSpecificPostalCodes: false,
      excludedPostalCodes: [],
      shippingStates: [],
      allPostalCodes: [],
      allStates:[],
      fields: ['selected', 'postal_code', 'postal_area', 'state'],
      supplierId: null,
      loading: true,
      supplierDetails:null,
    }
  },
  computed:{
    allStatesNames(){
      return this.allStates.map(x=>x['name'])
      },
    vgtText(){
      return this.excludedPostalCodes.length===0 ? $t('Εξαίρεση Τ.Κ.') : $t('Εξαιρέσεις Τ.Κ. ')
    }
  },
  methods: {  
    selectAll(){
      this.excludedPostalCodes = this.allPostalCodes.map(x=>this.allPostalCodes.indexOf(x).toString())
      this.excludedPostalCodes.map(code=>this.$set(this.allPostalCodes[code], 'vgtSelected', true));
      this.keykey ++
    },
    clearAll(){
      this.allPostalCodes.map(x=>this.allPostalCodes.indexOf(x).toString()).map(code=>this.$set(this.allPostalCodes[code], 'vgtSelected', false));
      this.excludedPostalCodes=[]
      this.keykey --
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    submitForm(){
        this.loading = true
        this.$store.dispatch('user/editSupplierDetails', {
          'id': this.supplierId,
          'shipping_terms': this.shippingTerms,
          'shipping_states': this.allStates.filter(state=>this.shippingStates.find(x=>x===state['name'])).map(y=>y['id']),
          'shipping_postal_codes': [...(this.$refs['excludedCodes'].selectedRows.map(x=>x['originalIndex']))]       
        })
        .then( () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                  title: this.$("Shipping info successfully changed!"),
                  icon: 'CheckIcon',
                  variant: 'success',
              },
          })
          // let final = []
          // this.shippingStates.map(state=>{
          //   final.push(...this.allPostalCodes.filter(x=>x['state']===state))
          // })
          // this.allPostalCodes = final
        })
        .catch( () => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$("Error Submiting Form"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        }) 
        .finally(()=>{this.loading = false})      
    },
  },
  created() {
   this.loading = true;

    if (!store.hasModule('shipping')) store.registerModule('shipping', moduleShipping)

    ///////////////////////////////////////////////////////////////////////////////
    
    let allStates = this.$store.getters["shipping/allStates"]   
    if(typeof allStates != "undefined"){
      this.allStates = [];
    }
    else{
     this.$store.dispatch("shipping/getStates")
        .then((allStates) => {
          this.allStates= allStates.data.result

          let allPostalCodes = this.$store.getters["shipping/allPostalCodes"]   
          if(typeof allPostalCodes != "undefined"){
            this.allPostalCodes = [];
          }
          else{
          this.$store.dispatch("shipping/getPostalCodes")
              .then((allPostalCodes) => {         
                this.allPostalCodes = allPostalCodes.data.result
                  .map(function(item){
                    return {
                      'postal_code': item['postal_code'].toString(),
                      'postal_area': item['postal_area'],
                      'state': item['state']
                      }
                  })
                  this.totalRows = this.allPostalCodes.length
                  if (!store.hasModule('user')) store.registerModule('user', moduleUser)
                  this.user = this.$store.state.user

                  if(this.user==null){
                    this.$store.dispatch("user/getUserDetails")
                      .then( (user) => {
                          this.user = user;                   
                      })
                      .catch(() => {
                          this.$toast({
                              component: ToastificationContent,
                              props: {
                                  title: this.$("Error fetching user"),
                                  icon: 'AlertTriangleIcon',
                                  variant: 'danger',
                              },
                          })
                      })
                  }

                  this.supplierId = this.user.supplierDetails.id; 
                  if(this.user.supplierDetails == null)  {
                      this.$store.dispatch("user/getSupplierDetails", this.supplierId)
                          .then((data)=>{
                            this.shippingTerms = data.shipping_terms;
                            this.shippingStates = data.includedStates.map(item=>this.allStates.find(state=>state['id'].toString()===item)).map(x=>x['name']);
                            this.excludedPostalCodes = data.excludedPostcodes;
                            this.excludedPostalCodes.map(code=>this.$set(this.allPostalCodes[code], 'vgtSelected', true));

                            // let final = []
                            // this.shippingStates.map(state=>{
                            //   final.push(...this.allPostalCodes.filter(x=>x['state']===state))
                            // })
                            // this.allPostalCodes = final              
                          }) 
                          .catch(()=>{this.$toast({
                                  component: ToastificationContent,
                                  props: {
                                      title: this.$("Error fetching Company Details"),
                                      icon: 'AlertTriangleIcon',
                                      variant: 'danger',
                                  },
                              })          
                          })
                          .finally(()=>{
                              this.loading = false
                          })
                  }
                  else{    
                      
                      let data = this.user.supplierDetails;
                      this.shippingTerms = data.shipping_terms;
                      this.shippingStates = data.includedStates.map(item=>this.allStates.find(state=>state['id'].toString()===item)).map(x=>x['name']);
                      this.excludedPostalCodes = data.excludedPostcodes;
                      this.excludedPostalCodes.map(code=>this.$set(this.allPostalCodes[code], 'vgtSelected', true));
                      this.loading = false
                  }
                })
              .catch(() => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: this.$("Error fetching States"),
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                      },
                  })
              })   
          }
        })
        .catch(() => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$("Error fetching States"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })  
    }

  },

}
</script>


<style lang="scss">
  .change-password {
      font-size: 0.8rem;
  }
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style> 
