var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":this.loading,"spinner-variant":"primary"}},[_c('div',{attrs:{"id":"shippingInfoDiv"}},[_c('div',[_c('b-card',{attrs:{"title":_vm.$t('shippingTerms')}},[_c('validation-provider',{attrs:{"name":"Shipping Terms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"sm:w-1/4",attrs:{"disabled":"","label":"Shipping terms","name":"item-shipping-terms"},model:{value:(_vm.shippingTerms),callback:function ($$v) {_vm.shippingTerms=$$v},expression:"shippingTerms"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',[_c('b-card',{attrs:{"title":_vm.$t('shippingLocations')}},[_c('app-collapse',[_c('app-collapse-item',{attrs:{"title":_vm.$t('includeSpecificStates')}},[_c('div',[_c('v-select',{attrs:{"disable-lookup":"","dir":"ltr","multiple":"","label":"title","options":_vm.allStatesNames},model:{value:(_vm.shippingStates),callback:function ($$v) {_vm.shippingStates=$$v},expression:"shippingStates"}})],1)]),_c('app-collapse-item',{attrs:{"title":_vm.$t('excludeSpecificPostalCodes')}},[_c('b-row',[_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.selectAll}},[_vm._v(" Select All ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.clearAll}},[_vm._v(" Clear All ")])],1)]),_c('div',{staticClass:"m-2"},[_c('vue-good-table',{key:_vm.keykey,ref:"excludedCodes",attrs:{"columns":_vm.columns,"rows":_vm.allPostalCodes,"search-options":{
                enabled: false,
                externalQuery: _vm.searchTerm },"select-options":{
                enabled: true,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: _vm.$t('Εξαιρέσεις Τ.Κ.'),
                clearSelectionText: '',
                disableSelectInfo: false, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              },"sort-options":{
                enabled: true,
                initialSortBy: {field: 'postal_code', type: 'asc'}
              },"pagination-options":{
                enabled: true,
                perPage:_vm.perPage
              }},scopedSlots:_vm._u([{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)],1)],1)],1)],1),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }